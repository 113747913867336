import React from 'react'
import './../css/escalencer.css'
import datePic from './../assets/eight.png'
import pp from './../assets/profile.jpg'

export default function Escalencer() {
  return (
    <><div className="color-bg">
    <div>
        <img src="https://escalancer.com/img/logo.png" />
        <strong> Your Ultimate Learning Partner</strong>
    </div>

    <div className="scrolling-text-container">
        <div className="scrolling-text-inner" style={{
        '--marquee-speed': '20s',
        '--direction': 'scroll-left',
      }}
      role="marquee">
            <div className="scrolling-text">
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
            </div>
            <div className="scrolling-text">
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
                <div className="scrolling-text-item">Free Webinar <i className="bi bi-lightning-charge-fill"></i></div>
            </div>

        </div>
    </div>
    <div className="container-fluid bg-diagonal-split p-5 text-dark text-center">
        <h1 className="animated-text">Why Data Science as a career is Trending <i className="bi bi-trophy"></i></h1>
        <p className="animated-text">Let us help you to know more</p>
    </div>
    <div className="container-fluid bg-diagonal-split-2 p-5 text-dark text-center">
        
        <div className="row text-center">
            <div className="col-md-4">
                <div className="card mb-4">
                    <div className="card-body">
                        {/* <!-- https://www.flaticon.com/packs/november-calendar-8 --> */}
                        <h5 className="card-title"><img src={datePic} style={{ height: '2vh' }} /> Date</h5>
                        <p className="card-text" id="currentDate"> November 8, 2024</p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card mb-4">
                    <div className="card-body">
                        <h5 className="card-title"><i className="bi bi-watch"></i> Time</h5>
                        <p className="card-text" id="currentTime"> 7pm </p>
                    </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card mb-4">
                    <div className="card-body">
                        <h5 className="card-title"><i className="bi bi-play-circle-fill"></i> Duration</h5>
                        <p className="card-text" id="duration"> 60 mins</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="container-fluid bg-diagonal-split-2 d-flex justify-content-center align-items-center vh-12 "
        style={{ textAlign: 'center', }}>
        <div className="container d-flex justify-content-center align-items-center vh-10 transparent-border">
            <div className="profile-card">
                <img src={pp} alt="Profile Picture" className="profile-image" />
                <div>
                    <h4 className="mb-1">Keynote Speaker</h4>
                    <p className="text-muted mb-1">Harshit Gupta</p>
                    <button className="btn btn-primary">Computer Scientist (Adobe Systems)</button>
                </div>
            </div>
        </div>

    </div>

    <div className="container-fluid bg-diagonal-split-2 p-5 text-dark text-center">
        <div className="row text-center">
            <div className="col-md-6">
                <div className="custom-div">
                    <h3>About the Webinar</h3>
                    <p>Content</p>
                </div>
            </div>
            <div className="col-md-6">
                <div className="custom-div">
                    <h3>Register here</h3>
                    <p>Link</p>
                </div>
            </div>
        </div>
    </div>
    <div className="container-fluid bg-polka p-5 text-center" >
        
        <div className="container mt-5 transparent-border border-green">
        <h1 >Advantages of this Webinar</h1>
        </div>
        <div className="container mt-5 transparent-border border-green" >
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 1</h5>
                        <p>Detail about advantage 1.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 2</h5>
                        <p>Detail about advantage 2.</p>
                    </div>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 3</h5>
                        <p>Detail about advantage 3.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 4</h5>
                        <p>Detail about advantage 4.</p>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 5</h5>
                        <p>Detail about advantage 5.</p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="advantage-box border-dark-green">
                        <h5>Advantage 6</h5>
                        <p>Detail about advantage 6.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div style={{height:'35px'}}>

    </div>
</div>
<footer className="animated-footer">
    <h5>Join Our Free Webinar!</h5>
    <button className="btn btn-like">Join</button>
</footer>

</>
  );
}


