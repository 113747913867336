import { useState } from 'react';
import './App.css';
import Navbar from './components/Navbar';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import About from './components/About';
import Services from './components/Services';
import Pricing from './components/Pricing';
import Contact from './components/Contact';
import Escalencer from './components/Escalencer';


function App() {

  // const [text,setTest] = useState(0)
  
  // async function goldprice1() {
  //   let price = await goldprice.getLiveGoldPricePerOunceInDollars()
  //   console.log(`The currency price of one ounce of gold is $$({price}`);
  //   setTest(price) https://forms.gle/egQ5ayW7cF4zwZ3bA
  // <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSc736h5kA37FwJzMZrGxpD-zqWanYI26jjMvTUIgMyLwOMQPQ/viewform?embedded=true" width="640" height="1156" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
// }

  return (
    <>
    <Navbar title="SRHInnovations" />
    <Router>
      <Routes>
        <Route path="/" element={<About/>} />
        <Route path="/services" element={<Services/>} />
        <Route path="/pricing" element={<Pricing/>} />
        <Route path="/contact" element={<Contact/>} />
        <Route path="/escalencer" element={<Escalencer/>} />
      </Routes>
    </Router>
    
    </>
  );
}

export default App;
